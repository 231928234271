import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';
import { format, isBefore, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../api/apiConfig'; // Adjust the path to your API config

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.getdataorderanduser, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
        });

        if (!response.ok) {
          throw new Error(t('errorFetchingOrders'));
        }

        const data = await response.json();
        setOrders(data); // Assuming data is an array of objects with { id, date, MyOrders }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [t]);

  const getBackgroundColor = (date) => {
    const parsedDate = new Date(date);
    if (isBefore(parsedDate, new Date()) && !isToday(parsedDate)) {
      return 'red';
    }
    return 'green';
  };

  // Navigate to the order details page with the user and order details
  const handleCardClick = (order, nameUser) => {
    navigate(`/users/${order.MyOrders[0].User.iduser}/orders/${order.id}`, {
      state: {
        order: order, // Pass the order object
        nameUser: nameUser, // Pass the user name for display in OrderDetails
      },
    });
  };

  if (loading) return <div>{t('loading')}</div>;
  if (error) return <div>{t('error')}: {error}</div>;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>{t('orderListTitle')}</Typography>
      <Grid container spacing={3}>
        {orders.map((order) => {
          const user = order.MyOrders.length > 0 ? order.MyOrders[0].User : null;
          const userName = user ? user.name : 'No User Name';

          return (
            <Grid item xs={12} md={4} key={order.id}>
              <Card
                style={{ backgroundColor: getBackgroundColor(order.date), color: '#fff', cursor: 'pointer' }}
                onClick={() => handleCardClick(order, userName)} // Navigate on card click
              >
                <CardContent>
                  <Typography variant="h6">{t('orderId')}: {order.id}</Typography>
                  <Typography>{t('orderDate')}: {format(new Date(order.date), 'dd/MM/yyyy')}</Typography>
                  <Typography>{userName}</Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default OrderList;
