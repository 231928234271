// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8xwEH0_-HqDFbVwrw4KiWBp3bVP42T3M",
  authDomain: "ordersbone.firebaseapp.com",
  projectId: "ordersbone",
  storageBucket: "ordersbone.appspot.com",
  messagingSenderId: "259173970408",
  appId: "1:259173970408:web:77e055f99ce927bf93345d",
  measurementId: "G-VJDMTPB46C"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app,db };